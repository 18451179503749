import * as React from 'react';
import type { AppProps } from 'next/app';
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import NextNProgress from 'nextjs-progressbar';

import { useEffect } from 'react';
import { useRouter } from 'next/router';
import createEmotionCache from '../src/styles/createEmotionCache';
import theme from '../src/styles/theme';
import '../src/styles/globals.css';
import { setFirstTouchURL, setLastTouchURL } from '../src/config/cookie';

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const clientSideEmotionCache = createEmotionCache();
const lastTouchURLTargetPaths = ['seminars', 'whitepapers', 'whitepaperDownloads', 'curriculums', 'lectures', 'cases'];

const MyApp: React.FunctionComponent<MyAppProps> = (props) => {
  const router = useRouter();

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  useEffect(() => {
    const lastTouchURLIsIncluded = lastTouchURLTargetPaths.some((path) => router.asPath.includes(path));
    setFirstTouchURL();
    if (lastTouchURLIsIncluded) {
      setLastTouchURL();
    }
  }, [router.asPath]);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <NextNProgress color="#00000055" height={2} />
          <Component {...pageProps} />
        </SnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default MyApp;
